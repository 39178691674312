.student-books {
  width: 100vw;
  height: 100vh;
  background: url(../../../img/bg-clean.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & .books-container {
    overflow: hidden;

    & .scroll-container {
      width: 100%;
      //height: 100vh;
      height: calc(100vh - 100px);
      overflow-y: auto;

      & h5 {
        color: white;
      }
    }

    & .book-status-indicator {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: white;
      margin-bottom: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4 ease-out;
      opacity: 0.85;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        z-index: 4;
        transform: scale(1.1);
      }
    }

    & .default {
      background-color: white;
    }
  }
}

.book-modal-content {
  background-color: rgb(229, 235, 238);
  // background-color: rgb(245, 245, 245);
}

.book-modal-backdrop {
  background-color: black;
}
