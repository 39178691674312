.modal-container {
  display: none;
  position: fixed;
  z-index: 2001;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color: #000000, $alpha: 0.9);

  & span {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    cursor: pointer;
    transition: 0.3s;
  }

  & img {
    margin: auto;
    display: block;
    width: 60%;
    max-width: 60%;
  }

  & iframe {
    margin: auto;
    display: block;
    width: 60%;
    max-width: 60%;
  }

  & .caption {
    font-size: 30px;
    margin: auto;
    display: block;
    width: 60%;
    max-width: 60%;
    text-align: center;
    color: #ddd;
    padding: 10px 0;
    height: 65px;
  }
}

.dictionary-container {
  width: 100%;
  margin-top: 10em;

  & .dictionary-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border: 1px solid rgba($color: #000, $alpha: 0.05);
    border-width: 0 0 1px 0;
    margin-bottom: 18em;
    padding-bottom: 7em;

    & span {
      display: block;
      margin-left: 0.5em;
      font-size: 13em;
      line-height: 1.1em;
      letter-spacing: 0.75px;
      color: #be5fb5;
      font-weight: 600;
    }
  }

  // & .dictionary-search {
  //   width: 100%;
  //   position: relative;
  //   margin-bottom: 12em;

  //   & .search-icon {
  //     position: absolute;
  //     right: 0;
  //     bottom: 2.5em;
  //   }

  //   & input {
  //     outline: 0;
  //     border-width: 0 0 1px 0;
  //     border-color: rgba($color: #000, $alpha: 0.1);
  //     background-color: transparent;
  //     width: 100%;
  //     font-size: 12em;
  //     padding-right: 2em;
  //     color: #555;
  //   }
  // }

  & .word-list {
    overflow: auto;
    height: 540em;

    & .word {
      margin-bottom: 20em;

      & a {
        width: 100%;
        align-items: center;
        color: #999;
        text-decoration: none;
        cursor: pointer;

        & span {
          font-weight: 400;
          font-size: 13em;
        }
      }

      & .meaning-list {
        border: 1px solid rgba($color: #000, $alpha: 0.05);
        border-width: 0 0 1px 0;

        & .meaning-item {
          margin-top: 10em;

          & .meaning {
            margin: 10em 0 15em 0;

            & span {
              font-size: 10em;
              line-height: 1.6;
            }
          }

          & .sample-sentence {
            margin: 10em 0 15em 0;

            & span {
              font-size: 10em;
              line-height: 1.6;
              font-style: italic;
            }
          }

          & .visual-content {
            margin: 10em 0 15em 0;
            display: flex;
            justify-content: space-between;

            & .meaning-image {
              width: 80em;
              height: 45em;
              cursor: pointer;

              & img {
                max-width: 100%;
                min-width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            & .meaning-video {
              width: 80em;
              height: 45em;
              cursor: pointer;
              position: relative;

              // & img {}

              & .play-icon-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
