.condition-content {
  & h2 {
    text-align: center;
  }

  & h3 {
    font-size: 1.6em;
    text-align: center;
    font-weight: 600;
  }

  & ul {
    list-style: none;
  }

  & h4 {
    font-weight: 500;
    text-decoration: underline;
  }

  & p {
    font-weight: 400;
    font-size: 1.2em;
  }

  & ol {
    list-style: none;

    & li {
      margin-bottom: 1em;
      font-size: 1.2em;
      font-weight: 400;
    }
  }
}
