@import '../constants';

.popover-wrapper {
  position: relative !important;

  // & .popover-trigger {
  //   position: relative;
  //   z-index: auto !important;
  // }

  & .popover-container {
    z-index: 1060;
    display: block !important;
    margin-bottom: 10em !important;
    position: absolute !important;
    top: -40% !important;
    left: 15em !important;
    height: auto !important;
    min-width: 250em;
    min-height: 50em;
    background-color: white !important;
    transform: translateX(-50%) translateY(-100%) !important;
    border-radius: 15em !important;
    box-shadow: 0px 10em 25em 0px rgba(0, 0, 0, 0.3) !important;

    & .popover-arrow {
      display: inline-block !important;
      position: absolute !important;
      left: 50% !important;
      bottom: 0 !important;
      width: 12em !important;
      height: 12em !important;
      border-radius: 2em !important;
      transform: translateX(50%) translateY(50%) rotate(45deg) !important;
      background-color: white !important;
    }

    & .popover-content {
      padding: 10em !important;
    }
  }

  & .hidden {
    display: none !important;
  }
}

@media screen and (min-width: $ratio-left-width) {
  .popover-wrapper {
    & .popover-container {
      margin-bottom: 0 !important;
      margin-left: 10em !important;
      top: 50% !important;
      left: 100% !important;
      transform: translateX(0%) translateY(-50%) !important;

      & .popover-arrow {
        left: 0 !important;
        top: 50% !important;
        transform: translateX(-50%) translateY(-50%) rotate(45deg) !important;
      }
    }
  }
}

@media screen and (min-width: $ratio-left-height) {
  .popover-wrapper {
    & .popover-container {
      margin-bottom: 0 !important;
      margin-left: 10em !important;
      top: 50% !important;
      left: 100% !important;
      transform: translateX(0%) translateY(-50%) !important;

      & .popover-arrow {
        left: 0 !important;
        top: 50% !important;
        transform: translateX(-50%) translateY(-50%) rotate(45deg) !important;
      }
    }
  }
}
// @media screen and (min-width: $ratio-left-height) {
//   .popover-wrapper {
//     position: relative !important;
//     z-index: 1060 !important;

//     & .popover-container {
//       display: block !important;
//       margin-left: 10em !important;
//       position: absolute !important;
//       top: 50% !important;
//       left: 100% !important;
//       width: 250em !important;
//       height: auto !important;
//       min-height: 50em !important;
//       background-color: white !important;
//       transform: translateY(-50%) !important;
//       border-radius: 15em !important;
//       box-shadow: 0px 10em 25em 0px rgba(0, 0, 0, 0.3) !important;

//       & .popover-arrow {
//         display: inline-block !important;
//         position: absolute !important;
//         left: 0 !important;
//         top: 50% !important;
//         width: 12em !important;
//         height: 12em !important;
//         border-radius: 2em !important;
//         transform: translateX(-50%) translateY(-50%) rotate(45deg) !important;
//         background-color: white !important;
//       }

//       & .popover-content {
//         padding: 10em !important;
//       }
//     }

//     & .hidden {
//       display: none !important;
//     }
//   }
// }
