.image-card-item {
  box-sizing: border-box;
  width: 150px;
  height: 100%;
  margin: 0 10px;
  border-radius: 5px;
  transition: 0.4s ease-out;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }

  & img {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    border-radius: 5px;
  }

  & .image-card-upper-layer-container {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5px 5px 0;
    z-index: 3;
  }
}

.disabled-book {
  filter: grayscale(1);
}
