$astronout-width: percentage(35/121);

.book-action-item {
  display: flex;
  align-items: center;
  cursor: pointer;

  & img {
    width: $astronout-width;
    min-width: $astronout-width;
    max-width: $astronout-width;
    object-fit: scale-down;
  }

  span {
    display: block;
  }

  .font-luckiest-guy {
    font-family: 'LuckiestGuy';
    letter-spacing: 1px;
    font-size: 14px;
    color: #371a45;
  }

  @media screen and (max-width: 768px) {
    .font-luckiest-guy {
      letter-spacing: 0.75px;
      font-size: 10px;
    }
  }
}

.book-warning-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & .book-warning-text {
    font-family: 'LuckiestGuy';
    letter-spacing: 2px;
    font-size: 40px;
    color: #371a45;
  }
}

@media screen and (max-width: 991.98px) {
  .book-warning-container {
    margin-left: 12px;

    & .book-warning-text {
      letter-spacing: 1px;
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 500px) {
  .book-warning-container {
    margin-left: 12px;

    & .book-warning-text {
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
}
