.image-card-slider-title {
  font-family: 'LuckiestGuy';
  font-size: 1.6em;
  letter-spacing: 2px;
}

.image-card-slider-wrapper {
  margin-bottom: 48px;
  display: flex;
  width: 100%;

  & .image-card-slider-btn {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & .prev {
    margin-right: 10px;
  }
  & .next {
    margin-left: 10px;
  }
  & .image-card-slider {
    padding: 0;
    overflow: hidden;
    position: relative;
    flex: 1;

    & .image-card-slider-container {
      display: flex;
      padding: 0;
      transition: transform 300ms ease 100ms;
      z-index: 3;
      width: 100%;
    }
  }
}
