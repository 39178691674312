.quiz-modal-container {
  padding: 20px 50px;
  background-color: rgb(220, 227, 231);

  & .quiz-modal-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    color: rgb(51, 51, 51);
  }

  & .quiz-modal-report-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    color: rgb(90, 90, 90);
    margin-top: 30px;
    padding-bottom: 30px;
  }

  & .quiz-modal-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;

    & .quiz-modal-tab-item {
      width: 50px;
      height: 50px;
      border-radius: 15px;
      background-color: rgb(136, 136, 136);
      color: white;
      text-align: center;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    & .active {
      background-color: rgb(103, 188, 236);
    }
    & .has-answer {
      background-color: rgb(165, 216, 246);
    }
    & .correct {
      background-color: rgb(101, 187, 114);
    }
    & .wrong {
      background-color: rgb(187, 105, 101);
    }

    & .not-clickable {
      cursor: default;
    }

    & p {
      font-size: 30px;
      font-weight: 700;
      display: inline-block;
    }
  }
  & .quiz-modal-question-wrapper {
    & .quiz-modal-question-container {
      padding: 20px;
      background-color: #f8f9fa;
      border-radius: 12px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      & .quiz-modal-play-btn {
        cursor: pointer;
      }
      & .quiz-modal-text-container {
        margin-right: auto;

        & .quiz-modal-question-title {
          margin-bottom: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          & p {
            font-size: 20px;
            line-height: 1.5;
            font-weight: 700;
            color: rgb(51, 51, 51);
            margin-left: 10px;
          }
        }

        & .quiz-modal-question-text {
          font-size: 20px;
          line-height: 1.5;
          font-weight: 400;
          color: rgb(51, 51, 51);
          margin-left: 10px;
          white-space: pre-wrap;
        }
      }
      & .quiz-modal-question-image {
        height: 150px;

        & img {
          height: 150px;
          min-height: 150px;
        }
      }
    }

    & .quiz-modal-question-choices {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & .quiz-modal-question-choice-item {
        margin-top: 30px;
        width: 436px;
        background-color: #f8f9fa;
        border-radius: 12px;
        padding: 20px 20px 20px 40px;
        display: flex;
        position: relative;
        cursor: pointer;

        & .quiz-modal-choice-item-image {
          height: 100px;

          & img {
            height: 100px;
            min-height: 100px;
          }
        }
      }

      // & .unmarked-choice {
      //   // background-color: rgba(101, 187, 114, 0.12);

      //   & .quiz-modal-choice-item-char {
      //     // background-color: rgb(101, 187, 114);
      //   }
      // }
      & .marked-choice {
        background-color: rgba(103, 188, 236, 0.22);

        & .quiz-modal-choice-item-char {
          background-color: rgb(103, 188, 236);
        }
      }

      & .correct-choice {
        background-color: rgba(101, 187, 114, 0.12);

        & .quiz-modal-choice-item-char {
          background-color: rgb(101, 187, 114);
        }
      }

      & .wrong-choice {
        background-color: rgba(187, 105, 101, 0.12);

        & .quiz-modal-choice-item-char {
          background-color: rgb(187, 105, 101);
        }
      }

      & .only-image {
        border-radius: 12px;
        padding: 0;

        & .quiz-modal-choice-item-image {
          border-radius: 12px;
          height: 200px;
          width: 100%;

          & img {
            border-radius: 12px;
            height: 200px;
            min-height: 200px;
            width: 100%;
          }
        }
      }
      & .quiz-modal-choice-item-char {
        position: absolute;
        left: -10px;
        top: -20px;
        width: 50px;
        height: 50px;
        border-radius: 15px;
        background-color: rgb(136, 136, 136);
        color: white;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .quiz-modal-play-btn {
        cursor: pointer;
      }

      & .quiz-modal-choice-item-text {
        margin-left: 10px;
        margin-right: auto;
        font-size: 20px;
        font-weight: 400;
        line-height: 32.5px;
      }
    }
  }

  & .quiz-modal-close {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;

    & .close-btn {
      background-color: rgb(103, 188, 236);
      min-height: 50px;
      padding: 10px 20px;
      font-size: 20px;
      font-weight: 700;
      color: white;
      border-radius: 15px;
      cursor: pointer;
      margin-left: 10px;
    }

    & .gray {
      background-color: #888;
    }

    & .red {
      background-color: rgb(187, 105, 101);
    }

    & button {
      &:active,
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
}
