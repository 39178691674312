.custom-footer {
  color: #1d1d1f !important;

  & ul li a {
    color: #1d1d1f !important;
  }

  & .social-media {
    text-align: right;

    & ul {
      & li {
        display: inline-block;
        height: 2.375rem;
        width: 2.375rem;
        min-width: 2.375rem;
        padding: 0;
        font-size: 0.9375rem;
        overflow: hidden;
        position: relative;
        line-height: normal;

        & .social-media-item {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      & :not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  & .custom-copyright {
    text-align: center;
    font-size: 0.8571em;
    color: #1d1d1f;
    font-weight: 400;
    line-height: 3.5;
  }
}
