.student-messages {
  width: 100vw;
  height: 100vh;
  background: url(../../../img/bg-clean.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & .messages-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & .scroll-container {
      width: 100%;
      //height: 100vh;
      height: calc(100vh - 100px);
      overflow-y: auto;
      display: flex;
    }

    & .message-list {
      padding: 20px 0;

      & .message-item {
        display: flex;
        align-items: center;
        margin: 40px 0;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        & .sender-avatar {
          border-radius: 50%;
          border: 5px #677eb7 solid;
          margin-right: 10px;

          & img {
            border-radius: 50% !important;
            object-fit: cover;
            object-position: center;
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            max-width: 80px;
            max-height: 80px;
          }
        }

        & .message-container {
          flex: auto;

          & .message-box {
            color: black;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            border: 5px #677eb7 solid;
            background-color: white;

            & p {
              text-align: left;
              margin: 0;
            }

            & .message-book {
              width: 60px;
              min-width: 60px;
              max-width: 60px;
              border-radius: 3px;
            }
          }
          & .message-datetime {
            color: white;
            font-weight: 500;
          }
        }
      }
    }

    & .no-message {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      & img {
        max-width: 60%;
        max-height: calc(60vh - 120px);
      }

      & p {
        font-family: 'LuckiestGuy';
        color: white;
        font-size: calc(15vh - 30px);
        color: #ffffff;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}
