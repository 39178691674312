@import '../constants';

$seconds: 1.5;
$animation-seconds: $seconds + 0s;

$bgColor: #e5ebee;
// $bgColor: #dce3e7;

.read-book-page {
  overflow: hidden;

  & .book-button {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
    transition: transform $animation-seconds;
    -webkit-transition: -webkit-transform $animation-seconds;
    -moz-transition: -moz-transform $animation-seconds;
    -o-transition: -o-transform $animation-seconds;
    z-index: 999;
    margin: 0;
    padding: 0;
    font-size: 1em !important;

    &:focus,
    &:hover,
    &::before {
      outline: none;
      box-shadow: none;
      background-color: transparent;
    }

    & .next-prev-button {
      // color: black;
      opacity: 0.3 !important;

      &:hover {
        opacity: 0.6 !important;
      }

      &::before {
        background-color: transparent !important;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type='number'] {
    -moz-appearance: textfield;
  }
}

.read-book-page .read-book-section {
  height: 100vh;
  height: var(--app-height);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // justify-content: center;
  background-color: $bgColor;

  font-size: calc(100vw / 1080);
}

.read-book-page .main-book {
  width: 840em;
  height: 595em;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: 20em;

  & .book {
    position: relative;
    width: 420em;
    height: 595em;
    transition: transform $animation-seconds;
    -webkit-transition: -webkit-transform $animation-seconds;
    -moz-transition: -moz-transform $animation-seconds;
    -o-transition: -o-transform $animation-seconds;

    & .paper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      -webkit-perspective: 840em;
      perspective: 840em;

      & .front,
      & .back {
        background-color: white;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform-origin: left;
        transition: transform $animation-seconds;
        -webkit-transition: -webkit-transform $animation-seconds;
        -moz-transition: -moz-transform $animation-seconds;
        -o-transition: -o-transform $animation-seconds;
        outline: 0.1px solid rgba(#000000, 0.01);
        // outline: 1px solid rgba(#dadada, 0.01);
      }

      & .front {
        z-index: 1;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        // box-sizing: border-box;
        // box-shadow: inset 4px 0px 20px rgba(0, 0, 0, 0.25);
      }

      & .back {
        z-index: 0;
        // box-sizing: border-box;
        // box-shadow: inset -4px 0px 20px rgba(0, 0, 0, 0.25);
      }

      & .front-content,
      & .back-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }

      & .front-content {
        box-shadow: inset 4px 0px 20px rgba(0, 0, 0, 0.25);

        & .image {
          box-shadow: inset 4px 0px 20px rgba(0, 0, 0, 0.25);

          & > img {
            position: relative;
            z-index: -2;
          }
        }
      }

      & .back-content {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg);

        box-shadow: inset -4px 0px 20px rgba(0, 0, 0, 0.25);

        & .image {
          box-sizing: border-box;
          box-shadow: inset -4px 0px 20px rgba(0, 0, 0, 0.25);

          & > img {
            position: relative;
            z-index: -2;
          }
        }
      }
    }
  }
}

.flipped .front,
.flipped .back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.text-area {
  width: 100%;
  height: 100%;
  padding-top: 14em;
  padding-left: 28em;
  padding-bottom: 28em;
  padding-right: 28em;
  display: flex;
  align-items: stretch;

  & .page-number {
    min-width: 14em;
    width: 14em;
    position: relative;
    line-height: 1;

    & span {
      position: absolute;
      bottom: 0;
      font-size: 11em;
    }
  }

  & .to-right span {
    right: 0;
  }

  & .to-left span {
    left: 0;
  }

  // LEVEL 1, 1+
  & .oka-futura-26 {
    font-size: 26em;
    line-height: calc(34 / 26);
  }

  // LEVEL 2, 2+
  & .oka-futura-24 {
    font-size: 24em;
    line-height: calc(32 / 24);
  }

  // LEVEL 3, 3+, 4, 4+, 5, 5+
  & .oka-futura-18 {
    font-size: 18em;
    line-height: calc(25 / 18);
  }

  // LEVEL 6, 7
  & .oka-futura-14 {
    font-size: 14em;
    line-height: calc(22 / 14);
  }

  // LEVEL 8, 9, 10
  & .oka-futura-12 {
    font-size: 12em;
    line-height: calc(19 / 12);
  }

  // Font Sizes out of OKA Standarts
  & .font-25 {
    font-size: 25em;
    line-height: calc(33 / 25);
  }

  & .font-22 {
    font-size: 22em;
    line-height: calc(30 / 22);
  }

  & .font-20 {
    font-size: 20em;
    line-height: calc(27 / 20);
  }

  & .font-16 {
    font-size: 25em;
    line-height: calc(23 / 16);
  }

  & .text-content {
    margin: 0;
    padding: 0;
    flex-grow: 1;

    & p {
      margin: 0;
      padding: 0;
      font-family: 'OKAFutura';
      font-weight: 400;
      vertical-align: top;
      font-size: 1em;
      line-height: inherit;
    }

    & mark {
      margin: 0;
      padding: 0;
    }

    & .highlight-word {
      background-color: #d4faa5 !important;
    }

    & .highlight-sentence {
      background-color: #fff2b5 !important;
    }
  }
}

.read-book-section .main-section {
  width: 100%;
}

.read-book-section .tools-menu {
  height: 100em;
  width: 100%;
  background-color: #f3f1f1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;

  & .icon-button-group {
    display: flex;
    flex-direction: inherit;
    justify-content: center;
    align-items: center;
  }
}

.icon-button-group .tool-input {
  width: 80em;
  height: 40em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4.8em;
  box-shadow: 0em 4em 4em rgba(0, 0, 0, 0.25);
  border-radius: 15em;
  border: 4em solid #e9912a;
  padding: 4em;
  background: #f3f1f1;

  & input {
    font-size: 14em;
    font-weight: 600;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    text-align: center;
    background-color: #f3f1f1;
    color: rgba(0, 0, 0, 0.5);
  }
}

.read-book-section .main-header {
  height: 100em;
  width: 100%;
  display: flex;
  justify-content: center;

  & .header-items {
    height: 100em;
    // width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & .header-item {
      height: 60%;

      & img {
        height: 100%;
        max-width: 100%;
        display: block;
      }
    }
  }
}

.read-book-section .main-body {
  display: flex;
  padding: 20em;
  justify-content: center;
}

.read-book-section .main-dictionary {
  height: 595em;
  width: 180em;
  margin-left: 20em;
}

.paper .image figure {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  object-fit: contain;
}
.paper .image img {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  object-fit: contain;
}

.icon-size20 {
  height: 20em;
  width: 20em;
}

.icon-size30 {
  height: 30em;
  width: 30em;
}

.icon-size40 {
  height: 40em;
  width: 40em;
}

.icon-size60 {
  height: 60em;
  width: 60em;
}

.icon-size90 {
  height: 90em;
  width: 90em;
}

#sliderVolume .noUi-handle {
  background-color: #67bcec !important;
}

.bookmark-container {
  font-size: 10em;
  max-height: 24em;
  width: 40em;

  & .bookmark-header {
    border-radius: 1em 1em 0 0;
    background-color: #be5fb5;
    width: 100%;
    height: 4.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .bookmark-header-title {
      padding-left: 1.3em;
      display: flex;
      align-items: center;

      & span {
        font-size: 1.5em;
        font-weight: bold;
        color: white;
        margin-left: 0.4em;
      }
    }

    & .bookmark-header-close {
      padding-right: 1.3em;
      cursor: pointer;
    }
  }

  & .bookmark-buttons {
    width: 100%;
    height: 4.5em;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    & .remove-bookmark-btn {
      font-size: 1.2em;
      font-weight: bold;
      color: #49443c;
      background-color: #facd00;
      padding: 0.5em;
    }

    & .append-bookmark-btn {
      font-size: 1.2em;
      font-weight: bold;
      color: white;
      background-color: #5da605;
      padding: 0.5em;
    }
  }

  & .bookmark-list {
    border-radius: 0 0 1em 1em;
    border: 1px solid #eaeaea;
    // padding: 0.5em;
    margin-top: 0.4em;
    overflow: scroll;
    max-height: 15em;
    min-height: 5em;

    & .bookmark-list-item {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;

      & .page-number-cell {
        flex: 1;
        text-align: center;
        font-size: 1.5em;
        font-weight: bold;
      }
    }

    & .new-item {
      background-color: #fce2f9;
      padding: 0.5em;
      border-radius: 0 0 0 0;

      & .btn-container {
        flex: 2;
      }

      & .new-item-input {
        flex: 3;
        font-size: 1.2em;
        border-radius: 0.3em;
        padding: 0.4em;
        background-color: white;

        &:focus {
          border-color: #be5fb5;
        }
      }
    }

    & .existing-item {
      & .btn-cell {
        flex: 1;
        text-align: center;
      }

      & .item-title {
        flex: 3;
        padding: 0.45em;
        font-size: 1.2em;
        font-weight: bold;
        color: #be5fb5;
        cursor: pointer;
      }
    }

    & .first-item {
      margin-top: 0.5em;
    }

    & .last-item {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: $ratio-bottom-height) {
  .read-book-page .read-book-section {
    font-size: calc(100vh / 835);
    font-size: calc(var(--app-height) / 835);
  }
}

@media screen and (min-width: $ratio-left-width) {
  .read-book-page .read-book-section {
    flex-direction: row-reverse;
    justify-content: flex-end;

    font-size: calc(100vw / 1180);
  }

  .read-book-section .tools-menu {
    height: 100%;
    width: 100em;
    background-color: #f3f1f1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
  }

  .icon-button-group .tool-input {
    width: 40em;

    // & input {
    //   font-size: 14em;
    //   font-weight: 600;
    // }
  }
}

@media screen and (min-width: $ratio-left-height) {
  .read-book-page .read-book-section {
    flex-direction: row-reverse;
    justify-content: flex-end;

    font-size: calc(100vh / 735);
    font-size: calc(var(--app-height) / 735);
  }

  .read-book-section .tools-menu {
    height: 100%;
    width: 100em;
    background-color: #f3f1f1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
  }

  .icon-button-group .tool-input {
    width: 40em;

    // & input {
    //   font-size: 14em;
    //   font-weight: 600;
    // }
  }
}
