.whole-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
}

.whole-screen-without-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-back {
  height: 100px;
  color: white;
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & h2 {
    margin: 0;
    font-weight: 700;
    font-size: 3em;
  }
}

@media screen and (max-height: 768px) {
  .go-back {
    height: 50px;
    color: white;
    padding-top: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & h2 {
      margin: 0;
      font-weight: 600;
      font-size: 1.5em;
    }
  }
}
