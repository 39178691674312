.student-report-container {
  font-size: 20px;
  line-height: 1;

  & .student-report-summary {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    // flex-wrap: wrap;

    & .student-container {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      & img {
        width: 40px;
        transform: translateY(10px);
      }

      & span {
        display: block;
        height: 100%;
        margin-left: 5px;
      }
    }

    & .summary-item {
      font-weight: 500;
    }

    & .logo {
      & img {
        height: 40px;
        min-width: 100%;
        object-fit: cover;
      }
    }
  }

  & .student-report-line {
    margin: 20px 0;
    width: 100%;
    height: 2px;
    background-color: rgb(44, 44, 44);
  }

  & .student-report-general-info {
    padding: 20px 0;
    margin-bottom: 40px;

    & .student-report-items-container {
      padding: 0 30px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      & .student-report-item {
        margin-top: 20px;
        text-align: center;
        min-width: 175px;
        & .item-value {
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        & .item-label {
          font-size: 24px;
          font-weight: 300;
        }
      }
    }
  }

  & .charts-container {
    & .chart-container {
      margin-bottom: 100px;
    }
  }

  & .section-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.student-report-container-to-pdf {
  padding: 40px;
  font-size: 20px;
  line-height: 1;
  display: none;
  width: 1250px;

  & .student-report-summary {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    // flex-wrap: wrap;

    & .student-container {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      & img {
        width: 40px;
        transform: translateY(10px);
      }

      & span {
        display: block;
        height: 100%;
        margin-left: 5px;
      }
    }

    & .summary-item {
      font-weight: 500;
    }

    & .logo {
      & img {
        height: 40px;
        min-width: 100%;
        object-fit: cover;
      }
    }
  }

  & .student-report-line {
    margin: 20px 0;
    width: 100%;
    height: 2px;
    background-color: rgb(44, 44, 44);
  }

  & .student-report-general-info {
    padding: 20px 0;
    margin-bottom: 40px;

    & .student-report-items-container {
      padding: 0 30px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      & .student-report-item {
        margin-top: 20px;
        text-align: center;
        min-width: 175px;
        & .item-value {
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        & .item-label {
          font-size: 24px;
          font-weight: 300;
        }
      }
    }
  }

  & .charts-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    & .chart-container {
      margin-bottom: 100px;
      width: 580px;
    }
  }

  & .section-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.summary-item-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.no-reports {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  // & img {
  //   max-width: 60%;
  //   max-height: calc(60vh - 120px);
  // }

  & p {
    font-family: 'LuckiestGuy';
    color: white;
    font-size: calc(15vh - 30px);
    // color: #000;
    font-weight: 400;
    text-align: center;
    background: url(../../../img/bg.svg) 0 0 / cover no-repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
