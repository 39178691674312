$astronout-width: percentage(35/121);

.assignment-action-item {
  display: flex;
  align-items: center;
  cursor: pointer;

  & img {
    width: $astronout-width;
    min-width: $astronout-width;
    max-width: $astronout-width;
    object-fit: scale-down;
  }

  span {
    display: block;
  }
}

.assignment-due-date {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;

  & span {
    display: inline-block;
    text-align: center;
  }
}

.font-luckiest-guy {
  font-family: 'LuckiestGuy';
  letter-spacing: 1px;
  font-size: 14px;
  color: #371a45;
}

@media screen and (max-width: 768px) {
  .font-luckiest-guy {
    letter-spacing: 0.75px;
    font-size: 10px;
  }
}
