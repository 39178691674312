.custom-navlink {
  cursor: pointer;

  & p {
    vertical-align: middle;
  }
}

.navbar p {
  line-height: 2.16em !important;
  font-size: 1.2em !important;
  vertical-align: middle;
}

.nav-item > a {
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
}

@media screen and (max-width: 991px) {
  .navbar-collapse:before {
    background: #32c1f9;
    background: linear-gradient(#32c1f9 0%, #000 80%);
  }
}

.no-shadow {
  box-shadow: none !important;
}
