@font-face {
  font-family: 'OKAFutura';
  font-weight: 800;
  src: local('OKAFuturaBold'),
    url(../../../fonts/OKAFuturaBold.ttf) format('truetype');
}

@font-face {
  font-family: 'OKAFutura';
  font-weight: 600;
  src: local('OKAFuturaHeavy'),
    url(../../../fonts/OKAFuturaHeavy.ttf) format('truetype');
}

@font-face {
  font-family: 'OKAFutura';
  font-weight: 400;
  src: local('OKAFuturaBook'),
    url(../../../fonts/OKAFuturaBook.ttf) format('truetype');
}
