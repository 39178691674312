.carousel .title {
  text-align: left;
}

.slider-desc {
  list-style: none;
  text-align: left;
  padding-left: 0;
  margin-top: 30px;

  & li {
    margin-top: 10px;
    font-weight: 300;
    font-size: 1.5em;
    line-height: 1.45;
  }
}
