.home-features {
  position: relative;
  overflow: hidden;
  padding: 45px 0;

  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .container,
  .container-fluid,
  .title,
  .description {
    position: relative;
    z-index: 2;
    color: #333;
    white-space: pre-wrap;
  }

  .info-title {
    color: #333;
  }
  .info-horizontal {
    padding: 15px 0 0;

    &:first-child {
      padding-top: 45px;
    }

    & .home-feature-icons {
      color: rgba(0, 0, 0, 0.8) !important;
    }
  }

  .image-container {
    max-width: 1200px;
    position: relative;
    height: 550px;
    margin-top: 58px;

    img {
      max-width: 1200px;
      left: 100px;
      top: 0;
      height: 100%;
      position: absolute;
    }
  }
}

.schools-features {
  // position: relative;
  // overflow: hidden;
  padding: 90px 0;

  & .phone-container {
    max-width: 260px;
    margin: 60px auto 0;
  }

  & .info {
    max-width: none;
    margin: 0 auto;
    padding: 10px 0 0px;
  }

  & .info:first-child {
    padding-top: 130px;
  }

  & .container,
  & .container-fluid,
  & .title,
  & .description {
    position: relative;
    z-index: 2;
    color: #333;
    white-space: pre-wrap;
  }

  & .info-title {
    color: #333;
  }
}

[data-background-color='blue'] {
  background-color: #b9ebfc;
}

[data-background-color='orange'] {
  background-color: #ffc194 !important;
}

.zero-padding {
  padding: 0 !important;
}

.cr-logo {
  height: 45px;
  width: auto;
}

@media screen and (max-width: 768px) {
  .home-features .image-container img {
    left: 0px;
    top: 0;
    max-width: inherit;
    width: 100%;
    height: auto;
    position: relative;
  }

  .cr-logo {
    height: 30px;
  }
}
