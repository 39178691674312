.student-home-container {
  width: 100vw;
  height: 100vh;
  background: url(../../../img/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & .student-exit-btn {
    position: absolute;
    top: 1vh;
    right: 1vw;
    width: 10vw;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    & .student-exit-btn {
      top: 12px;
      right: 12px;
      width: 120px;
    }
  }

  & .student-reports-btn {
    position: absolute;
    top: 1vh;
    left: 1vw;
    width: 10vw;
    cursor: pointer;

    & .student-reports-image-container {
      position: relative;

      & .student-report-btn-image {
        position: absolute;
        top: 0;
      }

      & .student-report-point-container {
        position: absolute;
        top: 5.2vw;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 10vw;

        & .student-report-point {
          display: flex;
          width: 100%;
          align-items: stretch;
          justify-content: center;

          & img {
            height: 2vw;
            width: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    & .student-reports-btn {
      top: 12px;
      left: 12px;
      width: 120px;

      & .student-reports-image-container {
        & .student-report-point-container {
          top: 62px;

          & .student-report-point {
            & img {
              height: 24px;
              width: auto;
            }
          }
        }
      }
    }
  }

  & .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
  }
}

// @keyframes shaking {
//   from {
//     transform: rotate(0);
//   }

//   50% {
//     transform: rotate(5deg);
//   }

//   to {
//     transform: rotate(0);
//   }
// }
