.login-input {
  & .input-group-text {
    background-color: white !important;
  }

  & input {
    color: #555555 !important;

    &::placeholder {
      color: #888888 !important;
    }
  }
}
